import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Table, InputGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import EurekaMapa from '../../../estructura/EurekaMapa/EurekaMapa';
import { EnviarMensaje, GetEureka, PostEureka } from '../../../estructura/EurekaGeneral/EurekaGeneral';
import Select from 'react-select';

interface IFormFormulario {
  formDisabled: boolean;
  handleClose: Function;
  setRefresh: Function;
  handleCreate: Function
}

interface IUseForm {
  nro_venta: string;
  recibe: string;
  telefono: string;
  direccion: string;
  observaciones: string;
  productos: IProducto[];
  codconector: string;
  codpostal: string;
  ubicacion: string;
}

interface IProducto {
  cant: number;
  sku: string;
  prod: string;
  id?: string;
}

interface ICoordenadas {
  coordenadas: {
    lat: number;
    lng: number;
  };
  idestado: number;
  datos: {
    tipoentrega: string;
    nroventa: string;
    sucursal: string;
    direccion: string;
  };
}

const FormFormulario: React.FC<IFormFormulario> = ({ formDisabled, handleClose, setRefresh, handleCreate }) => {
  const [producto, setProducto] = useState<IProducto>({ cant: 0, sku: '', prod: '' });
  const [combosConector, setCombosConector] = useState([]);
  const [productos, setProductos] = useState<IProducto[]>([]);
  const [ubicacion, setUbicacion] = useState('Buenos Aires, Argentina');
  const [coordenadas, setCoordenadas] = useState([ -34.6037, -58.3816 ]);

  const [direccion, setDireccion] = useState('');
  const [codigoPostal, setCodigoPostal] = useState('');
  const [idconector, setIdconector] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IUseForm>({
    defaultValues: {
      nro_venta: '',
      recibe: '',
      telefono: '',
      direccion: '',
      observaciones: '',
      productos: [],
      codconector: '',
      codpostal: '',
      ubicacion: '',
    },
  });
  function escribiendoProducto(e: React.ChangeEvent<HTMLInputElement>) {
    setProducto({ ...producto, [e.target.name]: e.target.value });
  }
  function submitProduc(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (producto.cant > 0 && producto.sku !== '' && producto.prod !== '') {
      setValue('productos', [...productos, { ...producto }]);
      setProducto({ cant: 0, sku: '', prod: '' });
      setProductos([...productos, { ...producto, id: uuidv4() }]);
    }
  }
  function deleteProduct(id: string) {
    let productoDelete = productos.filter((value) => value.id !== id);
    setProductos(productoDelete);
    setValue('productos', productoDelete);
  }
  function SubmitNovedad() {
    if (direccion) {
      buscarDireccion();
    }
    if (getValues().productos.length) {
      const data = {
        productos_json: getValues().productos,
        idconector: idconector
      }
      PostEureka('/entregas/valida/sku_cliente', data)
        .then((response) => {
          if (response.content.info) {
            PostEureka('/entregas/altaentrega', getValues())
              .then((response) => {
                console.log(response);
                if (response.content.info) {
                  EnviarMensaje('success', response.content.msg);
                  handleCreate();
                  handleClose();
                  setRefresh();
                } else {
                  EnviarMensaje('danger', response.content.content);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            EnviarMensaje('danger', response.content.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else EnviarMensaje('danger', 'Necesita al menos un producto para avanzar.');
  }
  function buscarDireccion() {
    setUbicacion(direccion);
  }
  const handleDireccionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDireccion(e.target.value);
    setValue('direccion', e.target.value)
  };
  const handleBlur = () => {
    if (direccion) {
      buscarDireccion(); 
    }
  };
  const handleCodigoPostalChange = (e:  React.ChangeEvent<HTMLInputElement>) => {
    setCodigoPostal(e.target.value);
    setValue('codpostal', e.target.value);
  };
  useEffect(() => {
    GetEureka('/novedades/combos').then((value) => {
      setCombosConector(value.content);
    });
  }, []);
  const handleConectorChange = (e: { value: string; idconectores: React.SetStateAction<string>; }) => {
    setValue('codconector', e.value);
    setIdconector(e.idconectores);
  };
  return (
    <form className='row mt-2' onSubmit={handleSubmit(SubmitNovedad)}>
      <div className='col-5'>
        <div className='col-12 d-flex align-items-center mb-3'>
          <Form.Label htmlFor='codconector' className='col-3'>
            Conector
          </Form.Label>
          <Select className='col-9' id='codconector' name='codconector' {...register('codconector', { required: true })} options={combosConector} 
          onChange={handleConectorChange} />
        </div>
        <div className='text-center p-0 mb-1'>{errors.codconector && <p className='text-danger'>Este campo es obligatorio</p>}</div>
        <div className='col-12 d-flex align-items-center mb-3'>
          <Form.Label htmlFor='nro_venta' className='col-3'>
            Nro Venta
          </Form.Label>
          <Form.Control md='4' type='text' disabled={formDisabled} name='nro_venta' {...register('nro_venta', { required: true })} />
        </div>
        <div className='text-center p-0 mb-1'>{errors.nro_venta && <p className='text-danger'>Este campo es obligatorio</p>}</div>
        <div className='col-12 d-flex align-items-center mb-3'>
          <Form.Label htmlFor='recibe' className='col-3'>
            Recibe
          </Form.Label>
          <Form.Control md='4' type='text' disabled={formDisabled} name='recibe' {...register('recibe', { required: true })} />
        </div>
        <div className='text-center p-0 mb-1'>{errors.recibe && <p className='text-danger'>Este campo es obligatorio</p>}</div>
        <div className='col-12 d-flex align-items-center mb-3'>
          <Form.Label htmlFor='telefono' className='col-3'>
            Telefono
          </Form.Label>
          <Form.Control md='4' type='text' disabled={formDisabled} name='telefono' {...register('telefono', { required: true })} />
        </div>
        <div className='text-center p-0 mb-1'>{errors.telefono && <p className='text-danger'>Este campo es obligatorio</p>}</div>
        <div className='col-12 d-flex align-items-center mb-3'>
          <Form.Label htmlFor='direccion' className='col-3'>
            Direccion
          </Form.Label>
          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-disabled'>Calle, localidad, provincia</Tooltip>}>
          <InputGroup>
            <Form.Control md='4' type='text' disabled={formDisabled} name='direccion' {...register('direccion', { required: true })} value={direccion} onChange={handleDireccionChange} onBlur={handleBlur} className='colorLetra' placeholder='Buenos Aires'/>
            <InputGroup.Text id='basic-addon1' className='input-group-flexit'>
              <i className="material-icons" onClick={buscarDireccion}>search</i>
            </InputGroup.Text>
          </InputGroup>
          </OverlayTrigger>
        </div>
        <div className='text-center p-0 mb-1'>{errors.direccion && <p className='text-danger'>Este campo es obligatorio</p>}</div>
        <div className='col-12 d-flex align-items-center mb-3'>
          <Form.Label htmlFor='codpostal' className='col-3'>
            Código postal
          </Form.Label>
          <Form.Control md='4' type='text' disabled={formDisabled} name='codpostal' {...register('codpostal', { required: true })} value={codigoPostal} onChange={handleCodigoPostalChange} className='colorLetra' />
        </div>
        <div className='text-center p-0 mb-1'>{errors.codpostal && <p className='text-danger'>Este campo es obligatorio</p>}</div>
        <div className='col-12'>
          <EurekaMapa height='400px' query={ubicacion} setCoordenadas={setCoordenadas} coordenadas={coordenadas} />
        </div>
      </div>
      <div className='col-7'>
        <div className='col-12 mb-2'>
          <Form.Label htmlFor='observaciones' className='col-3'>
            Observaciones
          </Form.Label>
          <Form.Control as='textarea' type='text' disabled={formDisabled} name='observaciones' {...register('observaciones')} />
        </div>
        <hr className='hrnew' />
        <div className='col-12'>
          <div className='row justify-content-center align-items-end'>
            <Form.Group controlId='exampleForm.ControlInput1' className='col-2 p-0'>
              <Form.Label>Cant</Form.Label>
              <Form.Control type='text' placeholder='Cantidad' name='cant' value={producto.cant} onChange={escribiendoProducto} />
            </Form.Group>
            <Form.Group controlId='exampleForm.ControlSelect1' className='col-2 p-0'>
              <Form.Label>Sku</Form.Label>
              <Form.Control type='text' placeholder='Sku' name='sku' value={producto.sku} onChange={escribiendoProducto} />
            </Form.Group>
            <Form.Group controlId='exampleForm.ControlTextarea1' className='col-6 p-0'>
              <Form.Label>Producto</Form.Label>
              <Form.Control type='text' placeholder='Producto' name='prod' value={producto.prod} onChange={escribiendoProducto} />
            </Form.Group>
            <Button variant='primary' className='col-1' onClick={(e) => submitProduc(e)}>
              {' '}
              +{' '}
            </Button>
          </div>
        </div>
        <hr className='hrnew' />
        <div className='col-12'>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>Cant.</th>
                <th>SKU</th>
                <th>Producto</th>
              </tr>
            </thead>
            <tbody>
              {productos?.map((value: any, key: number) => (
                <tr key={key}>
                  <td>
                    <button className='btn btn-sm btn-danger' onClick={() => deleteProduct(value.id)}>
                      x
                    </button>
                  </td>
                  <td>{value.cant}</td>
                  <td>{value.sku}</td>
                  <td>{value.prod}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className='col-12 d-flex justify-content-end'>
        <button type='button' className='btn w-50 btn-sm btn-danger me-1' onClick={() => handleClose()}>
          Cerrar
        </button>
        <button type='submit' className='btn w-50 btn-sm btn-primary me-1'>
          Aceptar
        </button>
      </div>
    </form>
  );
};

export default FormFormulario;
